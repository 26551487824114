export const SITE_KEY = process.env.NODE_ENV === 'development' ? 'qh2hb38gqs09kbp6' : '7ma75l25k7krhr2t'

export const prodEndpoints = {
  get_summary: 'get_summary',
  search_scans: 'https://s.streak.tech/screeners/basic_search',
  // get_screens: 'https://s.streak.tech/screeners/filter_search',
  get_screens: 'https://s.streak.tech/screeners/multi_search',
  tech_analysis: 'https://mo.streak.tech/api/tech_analysis/', // single
  analysis: 'https://mo.streak.tech/api/tech_analysis_multi/',
  resistance_analysis: 'https://mo.streak.tech/api/sr_analysis_multi/',
  get_instrument_token: 'https://s.streak.tech/instruments/exact_search_ninja/symbols',
  screener_tags: 'screener_tags/',
  heatmap: 'heatmap',
  marketplace: 'marketplace/',
  marketplace_tags: 'marketplace_tags/',
  fetch_marketplace_chart: 'fetch_marketplace_chart/',
  marketplace_backtest: 'marketplace_backtest/',
}

// when working on staging environment IN_PRODUCTION is false
export const IN_PRODUCTION = true // process.env.NODE_ENV !== 'development'
export const domain = IN_PRODUCTION ? 'tech' : 'ninja'
export const fetchCandlesURL = {
  india: 'https://datastore.streak.tech/candles/fetch/', // also referred as tech platform
  world: 'https://datastore.streak.world/candles/fetch/',
}
export const baseUrl = `https://api.streak.${domain}/`
export const summaryBaseUrl = 'https://ms.streak.ninja/'

export const kiteUrl = 'https://kite.zerodha.com/'

// backtest Service Urls
export const prodBtService = 'https://bt2.streak.ninja/'
export const prodBtFastService = 'https://btfast.streak.ninja/'
export const prodSbtFastService = 'https://btfast.streak.ninja/market_backtest_service'
export const prodSbtService = 'https://bt2.streak.tech/market_backtest_service'
// other api urls
export const shareUrl = 'https://public.streak.tech/'
export const alertScreenUrl = 'https://scan.streak.tech/api/'
export const notificationServerURL = 'https://ns.streak.tech/'
export const scannerUrl = 'https://scan.streak.tech/api/screener'
export const notifUrl = 'https://notif.streak.tech/notification'

// website url links
export const webBaseUrl = IN_PRODUCTION ? 'https://streak.zerodha.com/' : 'https://v4.streak.ninja/'
export const scannersTechUrl = 'https://scanners.streak.tech/'
export const termsPage = '/terms'
export const privacyPage = '/privacy'
export const helpPageUrl = 'https://academy.streak.tech/ ' // 'https://help.streak.ninja/'
export const embedUrl = 'https://embed.streak.tech/'

export const loginUrl = `https://api.streak.${domain}/broker_login_url`
export const pollUrl = 'https://notif.streak.tech/handle_post/'
export const newPollUrl = `https://ua.streak.${domain}/handle_post/`
export const msPollUrl = `https://os-analytics.streak.${domain}/ms_event`
export const supportUrl = 'https://support.streak.ninja/streak_connect/admin/'

// export const baseChartUrl = 'http://localhost:3001/'
export const baseChartUrl = 'https://wv-charts-streak.web.app/'

export const symbolIconsUrl = 'https://streak-in-public-static.s3.ap-south-1.amazonaws.com/'

export const s3Bucket = 'https://streak-public-assets.s3.ap-south-1.amazonaws.com/'

export const termsAndCondsUrl = `${baseUrl}${prodEndpoints.app_trading_terms}`

export const abSignUp = 'https://www.angelbroking.com/streak?utm_source=web&utm_medium=web&utm_campaign=AB-Site-streak'

export const s3BucketMap = {
  indicators: 'images/indicators/',
}
export const upstoxLoginUrl = IN_PRODUCTION
  ? `https://api.upstox.com/index/dialog/authorize?apiKey=115YlK3Wxl1C0ZYPYttwFajmV33V8FPEabOzu68t&redirect_uri=${webBaseUrl}login/upstox&response_type=code`
  : `https://api.upstox.com/index/dialog/authorize?apiKey=icmd1si662nXzeZoM5vi6ucJHYjeHMCa6lIM8wJ7&redirect_uri=${process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8080' : webBaseUrl}/login&response_type=code`

export const kiteLoginUrl = `https://kite.zerodha.com/connect/login?api_key=${SITE_KEY}`

export const paisaLoginUrl = 'https://login.5paisa.com/vlogin-page?vid=streak'

export const academyBaseUrl = `http://academy.streak.${domain}/`

export const btWasmUrl = 'https://streak-public-assets.s3.ap-south-1.amazonaws.com/bt_dist/lib.wasm.gz'

export const iciciLoginUrl = 'https://uatapi.icicidirect.com/apiuser/login?api_key=%23!621H441o858%600w00L7578788f7R60~'

export const zerodhaHomeUrl = 'https://zerodha.com/'

export const zerodhaSignupUrl = 'https://signup.zerodha.com/'
